<template>
    <div class="chexiaoPayAccounts">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['query','output','goBack']"
                @queryClick="loadData()"  
                @outputClick="exportExcel()" 
                @goBackClick="back()"/>
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="filter-box">
                    <div class="from-box">
                        <div class="lable-txt">收款日期:</div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                                v-model="businessHours"
                                type="daterange"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :default-time="defaultTime"
                            > </el-date-picker>
                        </div>
                    </div>
                    <div class="from-box">
                        <div class="lable-txt">收款方式:</div>
                        <div class="from-input">
                            <select-tree class="from-input" ref="payTree" v-model="Pay_IDs" placeholder="请选择收款方式" 
                                :data="treePayTypes" 
                                searchPlaceholder="编号名称模糊检索"
                                is-search
                                :filter-node-method="filterNode"
                                title="收款方式选择" />
                        </div>
                    </div>
                </div>
                <div class="table-box">
                    <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                        ref="tableEl"
                        border
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        show-summary
                        :summary-method="getSummaries"
                        @cell-click="orderDetail"
                        :data="pageTableData"
                    >
                        <el-table-column prop="Rpt_Date" label="收款日期" width="85"  :formatter="shortDateFormat" align="left"></el-table-column>
                        <el-table-column prop="Corp_Code" label="账户编号" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Corp_Name" label="账户名称" min-width="100" align="left"></el-table-column>
                        <el-table-column prop="Consume_Money" label="清账金额" min-width="80" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Pay_Money" label="收款金额" min-width="80" align="right" data-format="number">
                            <template #default="scope">
                                <span class="link">{{scope.row.Pay_Money}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Zk_Money" label="折扣金额" min-width="80" align="right" data-format="number" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Pay_Name" label="收款方式" min-width="85"></el-table-column>
                        
                        <el-table-column prop="Corp_Czy" label="操作人" min-width="100" align="left"></el-table-column> 
                        <el-table-column prop="Corp_Time" label="操作时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column> 
                        <el-table-column prop="Remark" label="备注" min-width="100" align="left"></el-table-column> 
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="filterData" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>pageTableData=list" 
                    :pageSizes="[20,30,50]"
                    :page-size="pageSize"
                    @sizeChange="(size)=>pageSize=size">
                </table-page-btn>
            </div>
        </div>
        <!-- 转账 -->
        <modal-load :isShow="chexiaoIsShow" >
            <chexiao-pay-model :isShow="chexiaoIsShow" @close="chexiaoIsShow=false" :data="chexiaoData" @confirm="cxConfirm"></chexiao-pay-model>
        </modal-load>
    </div>
</template>

<script>
import chexiaoPayModel from './chexiaoPayModel/chexiaoPayModel.vue';
/**AR账单 应收账户 撤销 */
export default {
    components: { chexiaoPayModel },
    name:"chexiaoPayAccounts",
    data(){
        let pageSize= this.$cacheData.get("chexiaoPayAccounts_pageSize")||20;
        return {
            Corp_Name:"",
            Corp_AutoID:"",
            /**账户的最后更新时间  操作数据时用 */
            LastUpdated_Time:null,
            /**付款方式数据 */
            payTypes:[],
            Pay_IDs:[],
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            currentPage:1,
            pageSize:pageSize,
            
            /**账单信息 */
            tableData:[],
            pageTableData:[],
            /**撤销弹层 */
            chexiaoIsShow:false,
            chexiaoData:null,
        }
    },
    computed:{
        /**选择 付款方式下拉数据*/
        treePayTypes(){
            let tree=[];
            this.payTypes?.forEach(it=>{
                let children=[];
                it.PayTypes.forEach(it=>{//1现金支付 2 普通支付 801010301 聚合支付  801010401 正品CRM3.0会员支付
                    if(it.INTERFACE_TYPE=="1" || it.INTERFACE_TYPE=="2" || it.INTERFACE_TYPE=="801010301" || it.INTERFACE_TYPE=="801010401"){
                        children.push(Object.assign({
                            id:it.PAY_ID,
                            name:it.PAY_CODE+"-"+it.PAY_NAME,
                        },it))
                    } 
                })
                if(children.length>0){
                    tree.push({
                        id:"kind"+it.PAYKIND_ID,
                        name:it.PAYKIND_CODE+"-"+it.PAYKIND_NAME,
                        children:children
                    });
                }
                
            })
            return tree;
        },
        /**筛选 */
        filterData(){
            let data=this.tableData||[];
            if(this.businessHours.length==2){
                let beginDate=new Date(new Date(this.businessHours[0]).Format("yyyy-MM-dd 00:00:00"));
                let endDate=new Date(this.businessHours[1]);

                data=data.filter(it=>{
                    let date=new Date(it.Rpt_Date);
                    return date>=beginDate && date<endDate;
                });
            }
            if(this.Pay_IDs.length>0){
                data=data.filter(it=> this.Pay_IDs.indexOf(it.Pay_Search)>=0);
            }
            return data;
        },
        /**合计数据 */
        summaryTableList(){
            let total={Consume_Money:0,Pay_Money:0,Zk_Money:0}
            this.filterData?.forEach((it)=>{
                total.Consume_Money+=it.Consume_Money;
                total.Pay_Money+=it.Pay_Money;
                total.Zk_Money+=it.Zk_Money;
            });
            return total;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("corpARMoney_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        let endDate=Rpt_Date.Format("yyyy-MM-dd");
        Rpt_Date.setDate(Rpt_Date.getDate()-30);
        let startDate=Rpt_Date.Format("yyyy-MM-dd");
        this.businessHours=[startDate,endDate];

        this.Corp_Name=this.$route.params.Corp_Name;
        this.Corp_AutoID=this.$route.params.id;
        this.LastUpdated_Time=this.$route.params.LastUpdated_Time;
        const loading = this.$loading({
            text: "数据加载中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        Promise.all([this.loadPayType(false),this.loadData(false)]).then(()=>{
            loading.close();
        }).catch(err=>{
            console.log(err) // 失败 
        })
    },
    methods:{
        back(){
            this.$router.push({
                name: 'corpARMoneyDetails', 
                params:{
                    id:this.Corp_AutoID,
                    Corp_Name:this.Corp_Name,
                    LastUpdated_Time:this.LastUpdated_Time
                }
            }); 
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.PAY_CODE?.toLocaleUpperCase().includes(value) || data.PAY_NAME?.toLocaleUpperCase().includes(value);
        },
        /**账户分类 */
        loadPayType(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            /* 获取支付方式 */
            return this.$cacheData.PayType().then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.payTypes=d;
            }).catch(e=>{
                this.$alert("付款方式数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**查询数据 */
        loadData(isLoaing){
            let loading;
            if(isLoaing!==false){
                loading = this.$loading({
                    text: "数据加载中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
            }
            return this.$httpAES.post("Bestech.CloudPos.GetARCorpMoneyInfo", {Corp_AutoID:this.Corp_AutoID}).then((d)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    console.log(d)
                    this.tableData=d.ResponseBody;
                }
            }).catch((e)=>{
                if(isLoaing!==false){
                    loading.close();
                }
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.tablePageIndex++;
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                this.tablePageIndex>0?this.tablePageIndex--:this.tablePageIndex=0;
            }
        },
        /**导出excel */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"应收账户清账记录流水表",
                list:this.filterData
            })
        },
        //合计
        getSummaries({ columns, data }){
            const sums= [];
            let total=this.summaryTableList;
            columns.forEach((column, index) => {
                if(total[column.property]!=undefined){
                    sums[index]= parseFloat(total[column.property].toFixed(2));
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**点击金额 进行撤销 */
        orderDetail(row, column){
            if(column.property=="Pay_Money"){
                this.chexiaoIsShow=true;
                this.chexiaoData=row;
            }
        },
        /**撤销成功 */
        cxConfirm(time){
            this.LastUpdated_Time=time;
            this.chexiaoIsShow=false;
            let index= this.pageTableData.findIndex(it=>it.Corp_MoneyID==this.chexiaoData.Corp_MoneyID);
            if(index>=0){
                this.pageTableData.splice(index,1)
            }
        }
    }
}
</script>

<style lang="scss">
    @import './chexiaoPayAccounts.scss'
</style>